<template>
  <span>
    <v-data-table
      :loading="loading"
      :headers="computedHeaders"
      :disable-sort="false"
      :items="items"
      class="dataTable elevation-1"
      calculate-widths
      fixed-header
      :height="computedHeight"
      :dense="true"
      mobile-breakpoint="0"
    >
      <template #top>
        <v-row dense>
          <v-col cols="12" class="d-flex flex-row align-center justify-end">
            <v-tooltip top :max-width="300">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="primary"
                  @click="handleRefresh()"
                >
                  <v-icon>
                    {{ icons.mdiRefresh }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t("Refresh table") }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>

      <template #item.payment_at="{ value }">
        {{ value | moment("DD-MM-YYYY") }}
      </template>
      <template #item.created_at="{ value }">
        {{ value | moment("DD-MM-YYYY h:mm a") }}
      </template>
      <template #item.currency_id="{ value }">
        {{ value === "2" ? "Bs" : "USD" }}
      </template>
      <template #item.amount="{ item }">
        <span class="color-green-text">
          <span v-if="item.currency_id === '1'">
            {{ item.amount | currency }}
          </span>
          <span v-else>
            {{
              item.amount
                | currency({
                  symbol: "Bs.",
                  thousandsSeparator: ",",
                  fractionCount: 2,
                  fractionSeparator: ".",
                  symbolPosition: "front",
                  symbolSpacing: false,
                  avoidEmptyDecimals: undefined,
                })
            }}
          </span>
        </span>
      </template>
      <template #item.invoice_personalized="{ value }">
        {{ value ? $t("yes") : $t("no") }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu rounded=".rounded-lg" offset-y :disabled="loading">
          <template v-slot:activator="{ attrs, on }">
            <!-- class="white--text ma-5" -->
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item link @click="toggleDetailsModal([item])">
              <v-list-item-title>{{
                $t("manual_payments_pending_see_details")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </span>
</template>
<script>
import { mdiDotsVertical, mdiRefresh } from "@mdi/js";
import PendingManualPaymentDetailsModal from "./PendingManualPaymentDetailsModal.vue";
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    page: {
      required: true,
    },
    headers: {
      required: true,
    },
    items: {
      required: true,
    },
  },
  components: {
    PendingManualPaymentDetailsModal,
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiRefresh,
      },
    };
  },
  computed: {
    computedHeight() {
      if (this.items.length > 9) {
        return 500;
      }
      if (this.items.length > 7) {
        return;
      }
      if (this.items.length > 5) {
        return;
      }
      if (this.items.length > 3) {
        return;
      }
      if (this.items.length > 1) {
        return;
      }
    },
    computedHeaders() {
      let headers = this.headers;
      headers.map((head) => (head.text = this.$t(head.text)));
      return headers;
    },
  },
  methods: {
    handleRefresh() {
      this.$emit("refresh");
    },
    async toggleDetailsModal(item) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "60%",
        transition: "dialog-top-transition",
        actions: {
          false: "No",
          true: "Yes",
        },
        item: item[0],
      };
      const dialogInstance = await this.$dialog.showAndWait(
        PendingManualPaymentDetailsModal,
        params
      );
    },
  },
};
</script>
<style lang="scss">
.dataTable {
  tbody tr:nth-of-type(even) {
    background-color: rgba(58, 53, 65, 0.04);
  }

  tbody tr:hover {
    background-color: rgba(0, 5, 34, 0.08) !important;
  }

  tbody tr {
    border-bottom: hidden !important;
  }
}
</style>
