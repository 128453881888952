var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-data-table',{staticClass:"dataTable elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.computedHeaders,"disable-sort":true,"items":_vm.items,"calculate-widths":"","hide-default-footer":"","disable-pagination":"","fixed-header":"","mobile-breakpoint":"0","height":_vm.computedHeight,"dense":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row align-center justify-end",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":"","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.handleRefresh()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Refresh table"))+" ")])])],1)],1)]},proxy:true},{key:"item.add_cut",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.wallet_status === 2 ? "color-red-text" : "color-green-text"},[(item.wallet_status === 2)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(("-" + item.added_wallet)))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.added_wallet))+" ")])])]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("moment")(value,"DD-MM-YYYY h:mm a"))+" ")]}},{key:"item.wallet_amount",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value))+" ")]}},{key:"item.total_wallet_amount",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value))+" ")]}},{key:"item.wallet_description",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.handleOriginTranslate(value))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }